import { gql } from '@apollo/client/core';
import * as fragment from '../fragments/index';

export const UPDATE_CASE = gql`
  mutation UpdateCase(
    $caseId: Int!
    $approach: CaseApproach
    $assignedId: Int
    $surgeonId: Int
    $locationId: Int
    $fieldRepId: Int
    $surgeryDate: Date
    $isSurgeryDateTentative: Boolean
    $spineProfile: CaseSpineProfile
    $levels: LevelsInput
    $levelsMetadata: LevelsMetadataInput
    $includeRodTemplates: Boolean
    $firstName: String
    $middleName: String
    $lastName: String
    $birthDate: Date
    $gender: Gender
    $mrn: String
    $caseType: CaseType
    $pelvicIncidence: Float
    $slopeOfLineOfSight: Int
    $lumbarLordosis: Float
    $lumbarCoronalCobb: Float
    $sagittalVerticalAxis: Float
    $coronalBalance: Float
    $pco: [String]
    $pso: [String]
    $uiv: String
    $liv: String
    $excludedInstruments: [String]
    $tags: [Int]
    $additivePrintingVendor: Int
    $finalShipmentVendor: Int
  ) {
    updateCase(
      caseId: $caseId
      input: {
        approach: $approach
        assignedId: $assignedId
        fieldRepId: $fieldRepId
        spineProfile: $spineProfile
        levels: $levels
        levelsMetadata: $levelsMetadata
        includeRodTemplates: $includeRodTemplates
        surgeryDate: $surgeryDate
        isSurgeryDateTentative: $isSurgeryDateTentative
        surgeonId: $surgeonId
        locationId: $locationId
        caseType: $caseType
        pco: $pco
        pso: $pso
        uiv: $uiv
        liv: $liv
        excludedInstruments: $excludedInstruments
        patient: {
          firstName: $firstName
          lastName: $lastName
          middleName: $middleName
          birthDate: $birthDate
          gender: $gender
          mrn: $mrn
          patientRecord: {
            pelvicIncidence: $pelvicIncidence
            slopeOfLineOfSight: $slopeOfLineOfSight
            lumbarLordosis: $lumbarLordosis
            lumbarCoronalCobb: $lumbarCoronalCobb
            sagittalVerticalAxis: $sagittalVerticalAxis
            coronalBalance: $coronalBalance
          }
        }
        tags: $tags
        additivePrintingVendor: $additivePrintingVendor
        finalShipmentVendor: $finalShipmentVendor
      }
    ) {
      case {
        caseId
      }
    }
  }
`;

export const UPDATE_CASE_DICOM_XR_REQUIRED = gql`
  mutation UpdateCase($caseId: Int!, $isXrayNotRequired: Boolean!) {
    updateCase(caseId: $caseId, input: { isXrayNotRequired: $isXrayNotRequired }) {
      case {
        caseId
      }
    }
  }
`;

export const UPDATE_CASE_STUDY_DATE_EXPIRY = gql`
  mutation UpdateCaseStudyDateExpiry(
    $caseId: Int!
    $studyDate: Date!
    $expiryDate: Date!
    $reason: String!
  ) {
    updateCaseStudyDateExpiry(
      caseId: $caseId
      input: { studyDate: $studyDate, expiryDate: $expiryDate, reason: $reason }
    ) {
      case {
        caseId
      }
    }
  }
`;

export const UPDATE_PATIENT = gql`
  mutation UpdatePatient(
    $patientId: Int!
    $firstName: String
    $middleName: String
    $lastName: String
    $gender: Gender
    $birthDate: Date
    $mrn: String
    $contactCadence: PatientContactCadenceInput
    $contactPreference: PatientContactPreferenceInput
    $email: String
    $phoneNumber: String
    $hasPermissionToContact: Boolean
    $hasPermissionToSchedule: Boolean
    $participantId: String
  ) {
    updatePatient(
      patientId: $patientId
      input: {
        firstName: $firstName
        middleName: $middleName
        lastName: $lastName
        gender: $gender
        birthDate: $birthDate
        mrn: $mrn
        contactCadence: $contactCadence
        contactPreference: $contactPreference
        email: $email
        phoneNumber: $phoneNumber
        hasPermissionToContact: $hasPermissionToContact
        hasPermissionToSchedule: $hasPermissionToSchedule
        participantId: $participantId
      }
    ) {
      patient {
        patientId
      }
    }
  }
`;

export const UPDATE_PATIENT_RECORD = gql`
  mutation UpdatePatientRecord(
    $patientId: Int!
    $preopMeasurements: String
    $dicomMetadata: String
    $bmi: Float
    $odi: Float
    $boneDensity: String
    $tScoreSpine: Float
    $tScoreHip: Float
    $vasBack: Float
    $vasLeg: Float
    $pelvicIncidence: Float
    $sacralSlope: Float
    $pelvicTilt: Float
    $lumbarLordosis: Float
    $lumbarCoronalCobb: Float
    $coronalBalance: Float
    $sagittalVerticalAxis: Float
    $l4S1LordoticDistribution: Float
    $globalTilt: Float
    $c7SagittalVerticalLine: Float
    $slopeOfLineOfSight: Int
    $crestHeight: Int
    $s1AnteriorPubisLength: Int
    $s1AnteriorPubisAngle: Int
    $biFemoralAxisLength: Int
    $biFemoralAxisMidpointS1EndplateMidpoint: Int
    $biIliacWidth: Int
    $posteriorIliacCrestWidth: Int
    $hasPelvisHighPelvicIncidence: Boolean
    $hasPelvisHighCrest: Boolean
    $hasPelvisOblique: Boolean
  ) {
    updatePatientRecord(
      patientId: $patientId
      input: {
        preopMeasurements: $preopMeasurements
        dicomMetadata: $dicomMetadata
        bmi: $bmi
        odi: $odi
        boneDensity: $boneDensity
        tScoreSpine: $tScoreSpine
        tScoreHip: $tScoreHip
        vasBack: $vasBack
        vasLeg: $vasLeg
        pelvicIncidence: $pelvicIncidence
        pelvicTilt: $pelvicTilt
        sacralSlope: $sacralSlope
        lumbarLordosis: $lumbarLordosis
        lumbarCoronalCobb: $lumbarCoronalCobb
        coronalBalance: $coronalBalance
        sagittalVerticalAxis: $sagittalVerticalAxis
        l4S1LordoticDistribution: $l4S1LordoticDistribution
        globalTilt: $globalTilt
        c7SagittalVerticalLine: $c7SagittalVerticalLine
        slopeOfLineOfSight: $slopeOfLineOfSight
        crestHeight: $crestHeight
        s1AnteriorPubisLength: $s1AnteriorPubisLength
        s1AnteriorPubisAngle: $s1AnteriorPubisAngle
        biFemoralAxisLength: $biFemoralAxisLength
        biFemoralAxisMidpointS1EndplateMidpoint: $biFemoralAxisMidpointS1EndplateMidpoint
        biIliacWidth: $biIliacWidth
        posteriorIliacCrestWidth: $posteriorIliacCrestWidth
        hasPelvisHighPelvicIncidence: $hasPelvisHighPelvicIncidence
        hasPelvisHighCrest: $hasPelvisHighCrest
        hasPelvisOblique: $hasPelvisOblique
      }
    ) {
      patientRecord {
        patientRecordId
      }
    }
  }
`;

export const CANCEL_CASE = gql`
  mutation CancelCase(
    $caseId: Int!
    $cancelledAt: Date!
    $type: CaseCancellationType!
    $reason: CaseCancellationReason!
    $note: String
  ) {
    cancelCase(
      caseId: $caseId
      input: { cancelledAt: $cancelledAt, type: $type, reason: $reason, note: $note }
    ) {
      status
    }
  }
`;

export const UNCANCEL_CASE = gql`
  mutation UncancelCase($caseId: Int!, $reason: String!) {
    uncancelCase(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const UNDELETE_CASE = gql`
  mutation UndeleteCase($caseId: Int!, $reason: String!) {
    undeleteCase(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const CLONE_CASE = gql`
  mutation CloneCase($caseId: Int!, $reason: String!) {
    cloneCase(caseId: $caseId, input: { reason: $reason }) {
      caseId
    }
  }
`;

export const DELETE_CASE = gql`
  mutation DeleteCase($caseId: Int!, $reason: String!) {
    deleteCase(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const DELETE_IMPLANT_TEMPLATE_CACHE = gql`
  mutation DeleteImplantTemplateCache {
    deleteImplantTemplateCache {
      status
    }
  }
`;

export const DELETE_IMPLANT_DRAWINGS_CACHE = gql`
  mutation DeleteImplantDrawingsCache {
    deleteImplantDrawingsCache {
      status
    }
  }
`;

export const UPDATE_CASE_NUMBER = gql`
  mutation UpdateCaseNumber(
    $caseId: Int!
    $receivedAt: Date!
    $firstName: String!
    $lastName: String!
  ) {
    updateCaseNumber(
      caseId: $caseId
      input: { receivedAt: $receivedAt, patientFirstName: $firstName, patientLastName: $lastName }
    ) {
      case {
        caseId
      }
    }
  }
`;

export const CREATE_ASSET_DOWNLOAD_URL = gql`
  mutation CreateAssetDownloadUrl($assetId: Int!, $allowDeleted: Boolean) {
    createAssetDownloadUrl(input: { assetId: $assetId, allowDeleted: $allowDeleted }) {
      signedUrl
    }
  }
`;

export const CREATE_PLAN_ASSET_DOWNLOAD_URLS = gql`
  mutation CreatePlanAssetDownloadUrls($planId: Int!) {
    createPlanAssetDownloadUrls(input: { planId: $planId }) {
      assetUrls {
        assetType
        url
      }
    }
  }
`;

export const DELETE_PLAN = gql`
  mutation DeletePlan($planId: Int!) {
    deletePlan(planId: $planId) {
      status
    }
  }
`;

export const UNAPPROVE_PLAN = gql`
  mutation UnapprovePlan($planId: Int!) {
    unapprovePlan(planId: $planId) {
      status
    }
  }
`;

export const CREATE_ASSET = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  mutation CreateAsset(
    $caseId: Int
    $planId: Int
    $fileName: String!
    $assetType: AssetType!
    $metadata: JSON
    $size: Int!
    $sendNotification: Boolean
  ) {
    createAsset(
      input: {
        caseId: $caseId
        planId: $planId
        fileName: $fileName
        assetType: $assetType
        metadata: $metadata
        size: $size
        sendNotification: $sendNotification
      }
    ) {
      asset {
        ...assetFields
      }
      signedUrl
    }
  }
`;

export const CREATE_PHOTO_ASSET = gql`
  ${fragment.ASSET_FIELDS_FRAGMENT}

  mutation CreateAssetMapping(
    $originId: Int!
    $originType: AssetOriginType!
    $fileName: String!
    $assetType: AssetType!
    $metadata: JSON
    $size: Int!
  ) {
    createAssetMapping(
      input: {
        originId: $originId
        originType: $originType
        fileName: $fileName
        assetType: $assetType
        metadata: $metadata
        size: $size
      }
    ) {
      asset {
        ...assetFields
      }
      signedUrl
    }
  }
`;

export const UPDATE_ASSET_METADATA = gql`
  mutation UpdateAssetMetadata($assetId: Int!, $metadata: JSON) {
    updateAssetMetadata(assetId: $assetId, input: { metadata: $metadata }) {
      asset {
        assetId
      }
    }
  }
`;

export const COMPLETE_CASE_STAGE = gql`
  mutation CompleteCaseStage($caseId: Int!) {
    completeCaseStage(caseId: $caseId) {
      stage
    }
  }
`;

export const REVIEW_DHR = gql`
  mutation ReviewDHR($caseId: Int!, $isApproved: Boolean!, $reason: String!) {
    reviewDHR(caseId: $caseId, input: { isApproved: $isApproved, reason: $reason }) {
      status
    }
  }
`;

export const COMPLETE_CASE = gql`
  mutation CompleteCase($caseId: Int!, $results: CaseResultsInput!) {
    completeCase(caseId: $caseId, input: $results) {
      caseResult {
        caseId
      }
    }
  }
`;

export const UPDATE_CASE_RESULTS = gql`
  mutation UpdateCaseResults($caseId: Int!, $results: CaseResultsInput!) {
    updateCaseResults(caseId: $caseId, input: $results) {
      caseResult {
        caseId
      }
    }
  }
`;

export const EXPORT_ONSHAPE_PARTS = gql`
  mutation ExportOnShapeParts($caseId: Int!, $input: ExportOnShapePartsInput!) {
    exportOnShapeParts(caseId: $caseId, input: $input) {
      signedUrl
      filename
    }
  }
`;

export const DELETE_EXPORT_CLOUD_DESIGN = gql`
  mutation DeleteExportCloudDesign($cloudDesignQueueId: Int!) {
    deleteExportCloudDesign(cloudDesignQueueId: $cloudDesignQueueId) {
      status
    }
  }
`;

export const EXPORT_CLOUD_DESIGN = gql`
  mutation ExportCloudDesign($caseId: Int!, $input: ExportCloudDesignInput!) {
    exportCloudDesign(caseId: $caseId, input: $input) {
      result
    }
  }
`;

export const IMPLANT_CREATE_CUT = gql`
  mutation ImplantCreateCut($caseId: Int!, $input: ImplantCreateCutInput!) {
    implantCreateCut(caseId: $caseId, input: $input) {
      result
    }
  }
`;

export const EXPORT_CLOUD_DESIGN_CUT = gql`
  mutation ExportCloudDesignCut($caseId: Int!, $input: ExportCloudDesignCutInput!) {
    exportCloudDesignCut(caseId: $caseId, input: $input) {
      result
    }
  }
`;

export const EXPORT_CLOUD_DESIGN_CUT_AND_MESH = gql`
  mutation ExportCloudDesignCutAndMesh($caseId: Int!, $input: ExportCloudDesignCutAndMeshInput!) {
    exportCloudDesignCutAndMesh(caseId: $caseId, input: $input) {
      result
    }
  }
`;

export const DELETE_EXPORT_DAISY_SEGMENTATION = gql`
  mutation DeleteExportDaisySegmentation($daisySegmentationQueueId: Int!) {
    deleteExportDaisySegmentation(daisySegmentationQueueId: $daisySegmentationQueueId) {
      status
    }
  }
`;

export const EXPORT_DAISY_SEGMENTATION = gql`
  mutation ExportDaisySegmentation($caseId: Int!) {
    exportDaisySegmentation(caseId: $caseId) {
      result
    }
  }
`;

export const APPROVE_DAISY_SEGMENTATION = gql`
  mutation ApproveDaisySegmentation($caseId: Int!) {
    approveDaisySegmentation(caseId: $caseId) {
      status
    }
  }
`;

export const APPROVE_WITH_CHANGES_DAISY_SEGMENTATION = gql`
  mutation ApproveWithChangesDaisySegmentation($caseId: Int!, $reason: String!) {
    approveWithChangesDaisySegmentation(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const REJECT_DAISY_SEGMENTATION = gql`
  mutation RejectDaisySegmentation($caseId: Int!, $tags: [Int], $reason: String!) {
    rejectDaisySegmentation(caseId: $caseId, input: { reason: $reason, tags: $tags }) {
      status
    }
  }
`;

export const DELETE_ASSET = gql`
  mutation DeleteAsset($assetId: Int!) {
    deleteAsset(assetId: $assetId) {
      status
    }
  }
`;

export const DELETE_ASSET_AND_MAPPING = gql`
  mutation DeleteAssetAndMapping($assetId: Int!) {
    DeleteAssetAndMapping(assetId: $assetId) {
      status
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation DeleteEvent($eventId: Int!) {
    deleteEvent(eventId: $eventId) {
      status
    }
  }
`;

export const CREATE_CASE = gql`
  mutation CreateCase(
    $caseType: CaseType!
    $spineType: CaseSpineType!
    $spineProfile: CaseSpineProfile!
    $receivedAt: Date!
    $surgeryDate: Date
    $isSurgeryDateTentative: Boolean
    $surgeonId: Int
    $locationId: Int
    $assignedId: Int
    $fieldRepId: Int
    $approach: CaseApproach
    $levels: LevelsInput
    $levelsMetadata: LevelsMetadataInput
    $includeRodTemplates: Boolean
    $firstName: String
    $middleName: String
    $lastName: String
    $birthDate: Date
    $gender: Gender
    $mrn: String
    $dicomMetadata: String
    $pelvicIncidence: Float
    $slopeOfLineOfSight: Int
    $lumbarLordosis: Float
    $lumbarCoronalCobb: Float
    $sagittalVerticalAxis: Float
    $coronalBalance: Float
    $assets: [CreateCaseAssetInput]
    $pco: [String]
    $pso: [String]
    $uiv: String
    $liv: String
    $comment: String
    $tags: [Int]
    $excludedInstruments: [String]
    $additivePrintingVendor: Int
    $finalShipmentVendor: Int
  ) {
    createCase(
      input: {
        spineType: $spineType
        spineProfile: $spineProfile
        caseType: $caseType
        approach: $approach
        assignedId: $assignedId
        levels: $levels
        levelsMetadata: $levelsMetadata
        includeRodTemplates: $includeRodTemplates
        fieldRepId: $fieldRepId
        surgeonId: $surgeonId
        locationId: $locationId
        surgeryDate: $surgeryDate
        isSurgeryDateTentative: $isSurgeryDateTentative
        receivedAt: $receivedAt
        patient: {
          firstName: $firstName
          lastName: $lastName
          middleName: $middleName
          birthDate: $birthDate
          gender: $gender
          mrn: $mrn
          patientRecord: {
            dicomMetadata: $dicomMetadata
            pelvicIncidence: $pelvicIncidence
            slopeOfLineOfSight: $slopeOfLineOfSight
            lumbarLordosis: $lumbarLordosis
            lumbarCoronalCobb: $lumbarCoronalCobb
            sagittalVerticalAxis: $sagittalVerticalAxis
            coronalBalance: $coronalBalance
          }
        }
        assets: $assets
        pso: $pso
        pco: $pco
        uiv: $uiv
        liv: $liv
        comment: $comment
        tags: $tags
        excludedInstruments: $excludedInstruments
        additivePrintingVendor: $additivePrintingVendor
        finalShipmentVendor: $finalShipmentVendor
      }
    ) {
      case {
        caseId
        number
      }
      signedUrls {
        assetType
        signedUrl
        assetId
      }
    }
  }
`;

export const CREATE_COMMENT = gql`
  mutation CreateCaseComment(
    $caseId: Int!
    $comment: String!
    $disableNotifications: Boolean
    $commentType: CommentType
    $fileMetadata: JSON
    $fileName: String
    $fileSize: Int
  ) {
    createComment(
      caseId: $caseId
      input: {
        comment: $comment
        disableNotifications: $disableNotifications
        commentType: $commentType
        fileMetadata: $fileMetadata
        fileName: $fileName
        fileSize: $fileSize
      }
    ) {
      comment {
        commentId
      }
      signedUrl
    }
  }
`;

export const UPDATE_COMMENT = gql`
  mutation UpdateCaseComment($commentId: Int!, $isPinned: Boolean, $comment: String) {
    updateComment(commentId: $commentId, input: { isPinned: $isPinned, comment: $comment }) {
      comment {
        commentId
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteCaseComment($commentId: Int!) {
    deleteComment(commentId: $commentId) {
      status
    }
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $role: UserRole
    $reportsToId: Int
    $organizationId: Int
    $linkedOrganizationId: Int
    $locationId: Int
  ) {
    createUser(
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        role: $role
        reportsToId: $reportsToId
        organizationId: $organizationId
        linkedOrganizationId: $linkedOrganizationId
        locationId: $locationId
      }
    ) {
      user {
        userId
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($userId: Int!) {
    deleteUser(userId: $userId) {
      status
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $userId: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
    $status: UserStatus!
    $reportsToId: Int
    $organizationId: Int
    $userRegionTerritories: [UserRegionTerritoryInput]
    $linkedOrganizationId: Int
    $locationId: Int
  ) {
    updateUser(
      userId: $userId
      input: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        status: $status
        reportsToId: $reportsToId
        organizationId: $organizationId
        userRegionTerritories: $userRegionTerritories
        linkedOrganizationId: $linkedOrganizationId
        locationId: $locationId
      }
    ) {
      user {
        userId
      }
    }
  }
`;

export const UPDATE_USER_PERMISSIONS = gql`
  mutation UpdateUserPermissions($userId: Int!, $role: UserRole!, $permissions: [Permission]!) {
    updateUserPermissions(userId: $userId, input: { role: $role, permissions: $permissions }) {
      status
    }
  }
`;

export const UPSERT_SURGEON_PREFERENCES = gql`
  mutation UpsertSurgeonPreferences(
    $userId: Int!
    $preferredProcedures: [ImplantType]
    $sagittalGoalPrimary: SagittalGoalType
    $sagittalGoalSecondary: SagittalGoalType
    $sagittalGoalOther: String
    $coronalGoalPrimary: CoronalGoalType
    $coronalGoalSecondary: CoronalGoalType
    $navigationSystems: [NavigationSystemType]
    $navigationSystemOther: String
    $targetHeightMetric: TargetHeightMetricType
    $excludedInstruments: [String]
    $reusableInstruments: Boolean
    $note: String
    $surgeonPreferenceImplants: [UpsertSurgeonPreferenceImplantInput]
    $surgeonPreferenceImplantMeasurements: [UpsertSurgeonPreferenceImplantMeasurementInput]
  ) {
    upsertSurgeonPreferences(
      userId: $userId
      input: {
        userId: $userId
        preferredProcedures: $preferredProcedures
        sagittalGoalPrimary: $sagittalGoalPrimary
        sagittalGoalSecondary: $sagittalGoalSecondary
        sagittalGoalOther: $sagittalGoalOther
        coronalGoalPrimary: $coronalGoalPrimary
        coronalGoalSecondary: $coronalGoalSecondary
        navigationSystems: $navigationSystems
        navigationSystemOther: $navigationSystemOther
        targetHeightMetric: $targetHeightMetric
        excludedInstruments: $excludedInstruments
        reusableInstruments: $reusableInstruments
        note: $note
        surgeonPreferenceImplants: $surgeonPreferenceImplants
        surgeonPreferenceImplantMeasurements: $surgeonPreferenceImplantMeasurements
      }
    ) {
      preferences {
        surgeonPreferenceId
        userId
        sagittalGoalPrimary
        sagittalGoalSecondary
        sagittalGoalOther
        coronalGoalPrimary
        coronalGoalSecondary
        navigationSystems
        navigationSystemOther
        targetHeightMetric
        excludedInstruments
        reusableInstruments
        note
        createdAt
        updatedAt
        createdByUser {
          userId
          firstName
          lastName
        }
        updatedByUser {
          userId
          firstName
          lastName
        }
        surgeonPreferenceImplants {
          surgeonPreferenceImplantId
          surgeonPreferenceId
          implantType
          positioning
          direction
          orientation
          orientationByLevels {
            L3_L4
            L4_L5
            L5_S1
          }
          screwLength
          maximizeFootprint
          plusSizeImplant
          minimalHeightSpecification
          excludedImplantSizes
          note
        }
        surgeonPreferenceImplantMeasurements {
          surgeonPreferenceImplantMeasurementId
          surgeonPreferenceId
          implantType
          measurementType
          min
          max
        }
      }
    }
  }
`;

export const CREATE_ORGANIZATION = gql`
  mutation CreateOrganization($name: String!, $organizationType: OrganizationType!) {
    createOrganization(input: { name: $name, organizationType: $organizationType }) {
      organization {
        organizationId
      }
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization(
    $organizationId: Int!
    $name: String!
    $externalSyncDataSourceId: String
    $metadata: JSON
  ) {
    updateOrganization(
      organizationId: $organizationId
      input: {
        name: $name
        externalSyncDataSourceId: $externalSyncDataSourceId
        metadata: $metadata
      }
    ) {
      organization {
        organizationId
      }
    }
  }
`;

export const UPSERT_LOCATION = gql`
  mutation UpsertLocation(
    $description: String!
    $organizationId: Int!
    $locationId: Int
    $regionId: Int
    $territoryId: Int
    $associatedUserIds: [Int]
    $externalSyncDataSourceId: String
  ) {
    upsertLocation(
      organizationId: $organizationId
      input: {
        description: $description
        locationId: $locationId
        regionId: $regionId
        territoryId: $territoryId
        associatedUserIds: $associatedUserIds
        externalSyncDataSourceId: $externalSyncDataSourceId
      }
    ) {
      status
    }
  }
`;

export const UPSERT_PLAN_IMPLANT = gql`
  mutation UpsertPlanImplant(
    $planImplantId: Int
    $planId: Int!
    $level: LevelType!
    $partType: LevelPart!
    $bullet: JSON
    $position: JSON
    $rotation: JSON
    $referencePoints: JSON
    $screwLength: Int
    $ap: Int!
    $ml: Int!
    $threadHeight: Int!
    $excludedImplantSizes: [String]
    $obliqueThreadAngle: Int
    $cranialCaudalThreadAngle: Int
    $metadata: String
  ) {
    upsertPlanImplant(
      planImplantId: $planImplantId
      input: {
        planId: $planId
        level: $level
        partType: $partType
        bullet: $bullet
        position: $position
        rotation: $rotation
        referencePoints: $referencePoints
        screwLength: $screwLength
        ap: $ap
        ml: $ml
        threadHeight: $threadHeight
        excludedImplantSizes: $excludedImplantSizes
        obliqueThreadAngle: $obliqueThreadAngle
        cranialCaudalThreadAngle: $cranialCaudalThreadAngle
        metadata: $metadata
      }
    ) {
      status
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($locationId: Int!) {
    deleteLocation(locationId: $locationId) {
      status
    }
  }
`;

export const VALIDATE_TOKEN = gql`
  mutation ValidateToken($accessToken: String!, $idToken: String!) {
    validateToken(input: { accessToken: $accessToken, idToken: $idToken }) {
      token
      user {
        userId
        firstName
        lastName
        email
        role
        permissions
        userType
        status
      }
    }
  }
`;

export const VALIDATE_TOTP = gql`
  mutation ValidateTOTP($totp: String!) {
    validateTOTP(input: { totp: $totp }) {
      token
      user {
        userId
        firstName
        lastName
        email
        role
        permissions
        userType
        status
      }
    }
  }
`;

export const CREATE_AUTO_CORRECT_QUEUE_ENTRY = gql`
  mutation CreateAutoCorrectQueueEntry(
    $caseId: Int!
    $shouldGenerateMeasurements: Boolean
    $sagittalGoal: String
    $coronalGoal: String
    $heightRestorationGoal: String
    $activeLevelGoals: JSON
  ) {
    createAutoCorrectQueueEntry(
      input: {
        caseId: $caseId
        shouldGenerateMeasurements: $shouldGenerateMeasurements
        sagittalGoal: $sagittalGoal
        coronalGoal: $coronalGoal
        heightRestorationGoal: $heightRestorationGoal
        activeLevelGoals: $activeLevelGoals
      }
    ) {
      status
    }
  }
`;

export const DELETE_AUTO_CORRECT_QUEUE_ENTRY = gql`
  mutation DeleteAutoCorrectQueueEntry($autoCorrectQueueId: Int!) {
    deleteAutoCorrectQueueEntry(autoCorrectQueueId: $autoCorrectQueueId) {
      status
    }
  }
`;

export const UPDATE_PLAN_IS_ACTIVE = gql`
  mutation UpdatePlanIsActive($caseId: Int!, $planId: Int!) {
    updatePlanIsActive(caseId: $caseId, planId: $planId) {
      status
    }
  }
`;

export const UPDATE_PLAN_PLUS_LEVEL_SIZE = gql`
  mutation UpdatePlanPlusLevelSize($caseId: Int!, $planId: Int!, $plusLevelSize: Int!) {
    upsertPlan(caseId: $caseId, input: { planId: $planId, plusLevelSize: $plusLevelSize }) {
      plan {
        planId
      }
    }
  }
`;

export const UPDATE_PLAN_VERSION = gql`
  mutation UpdatePlanVersion($caseId: Int!, $planId: Int!, $version: Int!) {
    upsertPlan(caseId: $caseId, input: { planId: $planId, version: $version }) {
      plan {
        planId
      }
    }
  }
`;

export const UPDATE_PLAN_APPROVED_DATE = gql`
  mutation UpdatePlanApprovedDate($planId: Int!, $approvedAt: Date) {
    updatePlanApprovedDate(planId: $planId, input: { approvedAt: $approvedAt }) {
      approvedAt
    }
  }
`;

export const APPROVE_FORM_19 = gql`
  mutation ApproveForm19($caseId: Int!) {
    approveForm19(caseId: $caseId) {
      status
    }
  }
`;

export const REJECT_FORM_19 = gql`
  mutation RejectForm19($caseId: Int!, $reason: String!) {
    rejectForm19(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const REJECT_PROPOSED_PLAN = gql`
  mutation RejectProposedPlan($planId: Int!, $reason: String!) {
    rejectProposedPlan(planId: $planId, input: { reason: $reason }) {
      status
    }
  }
`;

export const CREATE_PLAN_REJECTION = gql`
  mutation CreatePlanRejection($planId: Int!, $note: String!, $reasons: [PlanRejectionReason]!) {
    createPlanRejection(planId: $planId, input: { note: $note, reasons: $reasons }) {
      status
    }
  }
`;

export const UPDATE_PLAN_REJECTION = gql`
  mutation UpdatePlanRejection($planId: Int!, $note: String!, $reasons: [PlanRejectionReason]!) {
    updatePlanRejection(planId: $planId, input: { note: $note, reasons: $reasons }) {
      success
    }
  }
`;

export const REJECT_READY = gql`
  mutation RejectReady($caseId: Int!, $reason: String!) {
    rejectReady(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const REJECT_PLANNING = gql`
  mutation RejectPlanning($caseId: Int!, $input: RejectReasonInput!) {
    rejectPlanning(caseId: $caseId, input: $input) {
      status
    }
  }
`;

export const REJECT_PRODUCTION = gql`
  mutation RejectProduction($caseId: Int!, $reason: String!) {
    rejectProduction(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const APPROVE_PROPOSED_PLAN = gql`
  mutation ApproveProposedPlan($caseId: Int!, $approvedAt: Date!) {
    approveProposedPlan(caseId: $caseId, input: { approvedAt: $approvedAt }) {
      status
    }
  }
`;

export const CREATE_EVENT = gql`
  mutation CreateCaseEvent(
    $caseId: Int!
    $eventType: EventType!
    $carrierType: CarrierType
    $trackingNumber: String
    $purchaseOrderNumber: String
    $note: String
    $description: String
    $occurredAt: Date!
    $dueDate: Date
    $fileName: String
    $fileSize: Int
    $fileMetadata: JSON
    $assetId: Int
  ) {
    createEvent(
      caseId: $caseId
      input: {
        eventType: $eventType
        carrierType: $carrierType
        note: $note
        trackingNumber: $trackingNumber
        purchaseOrderNumber: $purchaseOrderNumber
        description: $description
        occurredAt: $occurredAt
        dueDate: $dueDate
        fileName: $fileName
        fileSize: $fileSize
        fileMetadata: $fileMetadata
        assetId: $assetId
      }
    ) {
      event {
        eventId
        assetId
      }
      signedUrl
    }
  }
`;

export const CREATE_EVENTS = gql`
  mutation CreateCaseEvents(
    $caseIds: [Int]!
    $eventType: EventType!
    $carrierType: CarrierType
    $trackingNumber: String
    $note: String
    $description: String
    $occurredAt: Date!
  ) {
    createEvents(
      caseIds: $caseIds
      input: {
        eventType: $eventType
        carrierType: $carrierType
        note: $note
        trackingNumber: $trackingNumber
        description: $description
        occurredAt: $occurredAt
      }
    ) {
      status
    }
  }
`;

export const UPSERT_POST_OP_ANALYSIS = gql`
  mutation UpsertPostOpAnalysis(
    $caseId: Int!
    $analysisType: AnalysisType!
    $lumbarCoronalAngulation: Float
    $lumbarLordosis: Float
    $segmentalLumbarLordosis: SegmentalValue
    $angleToS1: SegmentalValue
    $segmentalCoronalAngle: SegmentalValue
    $segmentalAnteriorHeight: SegmentalValue
    $segmentalPosteriorHeight: SegmentalValue
    $pelvicTilt: Float
    $thoracicKyphosis: Float
    $sagittalVerticalAxis: Float
    $coronalBalance: Float
  ) {
    upsertPostOpAnalysis(
      caseId: $caseId
      input: {
        analysisType: $analysisType
        lumbarCoronalAngulation: $lumbarCoronalAngulation
        lumbarLordosis: $lumbarLordosis
        segmentalAnteriorHeight: $segmentalAnteriorHeight
        segmentalPosteriorHeight: $segmentalPosteriorHeight
        segmentalLumbarLordosis: $segmentalLumbarLordosis
        angleToS1: $angleToS1
        segmentalCoronalAngle: $segmentalCoronalAngle
        pelvicTilt: $pelvicTilt
        thoracicKyphosis: $thoracicKyphosis
        sagittalVerticalAxis: $sagittalVerticalAxis
        coronalBalance: $coronalBalance
      }
    ) {
      postOpAnalysis {
        postOpAnalysisId
      }
    }
  }
`;

export const UPSERT_SETTINGS = gql`
  mutation UpsertSettings(
    $emails: JSON
    $networkDays: JSON
    $form20: JSON
    $form19: JSON
    $implantDrawings: JSON
    $testerEmails: JSON
    $onShape: JSON
    $application: JSON
  ) {
    upsertSettings(
      input: {
        emails: $emails
        testerEmails: $testerEmails
        networkDays: $networkDays
        form20: $form20
        form19: $form19
        implantDrawings: $implantDrawings
        onShape: $onShape
        application: $application
      }
    ) {
      settings {
        emails
        testerEmails
        networkDays
        form20
        form19
        implantDrawings
        onShape
        application
      }
    }
  }
`;

export const UPSERT_SITE_CONFIGURATIONS = gql`
  mutation UpsertSiteConfigurations($configurations: ConfigurationInput!) {
    upsertSiteConfigurations(input: { configurations: $configurations }) {
      status
    }
  }
`;

export const CREATE_TASK = gql`
  mutation CreateCaseTask(
    $caseId: Int!
    $assignedId: Int
    $description: String!
    $status: TaskStatusType!
    $statusDescription: String
    $dueDate: Date
    $priority: TaskPriorityType
  ) {
    createTask(
      caseId: $caseId
      input: {
        assignedId: $assignedId
        description: $description
        status: $status
        statusDescription: $statusDescription
        dueDate: $dueDate
        priority: $priority
      }
    ) {
      task {
        taskId
      }
    }
  }
`;

export const UPDATE_TASK = gql`
  mutation UpdateTask($taskId: Int!, $status: TaskStatusType!, $statusDescription: String!) {
    updateTask(taskId: $taskId, input: { status: $status, statusDescription: $statusDescription }) {
      task {
        taskId
      }
    }
  }
`;

export const DELETE_TASK = gql`
  mutation DeleteTask($taskId: Int!) {
    deleteTask(taskId: $taskId) {
      status
    }
  }
`;

export const UPDATE_TAG = gql`
  ${fragment.TAG_FIELDS_FRAGMENT}

  mutation UpdateTag($tagId: Int!, $label: String, $tagCategoryId: Int) {
    updateTag(tagId: $tagId, input: { label: $label, tagCategoryId: $tagCategoryId }) {
      tag {
        ...tagFields
      }
    }
  }
`;

export const DELETE_TAG = gql`
  mutation DeleteTag($tagId: Int!) {
    deleteTag(tagId: $tagId) {
      status
    }
  }
`;

export const UPSERT_FORM19 = gql`
  mutation UpsertForm19(
    $caseId: Int!
    $isImageSliceValid: Boolean
    $levelNotes: JSON
    $data: JSON
    $engineeringApprovedAt: DateTime
    $engineeringApprovedBy: Int
    $qualityApprovedAt: DateTime
    $qualityApprovedBy: Int
    $peerReviewerId: Int
    $isNonConformance: Boolean
    $nonConformanceReason: String
    $caseRiskAssessment: CaseRiskAssessmentType
    $revision: Int
    $description: String
    $regenerateReason: String
  ) {
    upsertForm19(
      caseId: $caseId
      input: {
        isImageSliceValid: $isImageSliceValid
        levelNotes: $levelNotes
        data: $data
        engineeringApprovedAt: $engineeringApprovedAt
        engineeringApprovedBy: $engineeringApprovedBy
        qualityApprovedAt: $qualityApprovedAt
        qualityApprovedBy: $qualityApprovedBy
        peerReviewerId: $peerReviewerId
        isNonConformance: $isNonConformance
        nonConformanceReason: $nonConformanceReason
        caseRiskAssessment: $caseRiskAssessment
        revision: $revision
        description: $description
        regenerateReason: $regenerateReason
      }
    ) {
      form19 {
        form19Id
      }
    }
  }
`;

export const UPSERT_CASE_IMPLANT_SPECIFICATION = gql`
  mutation UpsertCaseImplantSpecification(
    $caseId: Int!
    $caseImplantSpecifications: [UpsertCaseImplantSpecification]!
  ) {
    upsertCaseImplantSpecification(
      caseId: $caseId
      input: { caseImplantSpecifications: $caseImplantSpecifications }
    ) {
      status
    }
  }
`;

export const SYNC_CASE_IMPLANT_SPECIFICATIONS = gql`
  mutation SyncCaseImplantSpecifications(
    $caseId: Int!
    $caseImplantSpecifications: [UpsertCaseImplantSpecification]!
  ) {
    upsertCaseImplantSpecification(
      caseId: $caseId
      input: { caseImplantSpecifications: $caseImplantSpecifications }
    ) {
      status
    }
  }
`;

export const CREATE_TAG_CATEGORY = gql`
  ${fragment.TAG_CATEGORY_FIELDS_FRAGMENT}

  mutation CreateTagCategory($label: String!, $color: String) {
    createTagCategory(label: $label, color: $color) {
      tagCategory {
        ...tagCategoryFields
      }
    }
  }
`;

export const UPDATE_TAG_CATEGORY = gql`
  ${fragment.TAG_CATEGORY_FIELDS_FRAGMENT}

  mutation UpdateTagCategory($tagCategoryId: Int!, $label: String, $color: String) {
    updateTagCategory(tagCategoryId: $tagCategoryId, input: { label: $label, color: $color }) {
      tagCategory {
        ...tagCategoryFields
      }
    }
  }
`;

export const DELETE_TAG_CATEGORY = gql`
  mutation DeleteTagCategory($tagCategoryId: Int!) {
    deleteTagCategory(tagCategoryId: $tagCategoryId) {
      status
    }
  }
`;

export const IMPORT_CASE_IMPLANT_SPECIFICATION = gql`
  mutation ImportCaseImplantSpecification($caseId: Int!) {
    importCaseImplantSpecification(caseId: $caseId) {
      status
    }
  }
`;

export const CREATE_PRICE_LEVEL = gql`
  mutation CreatePriceLevel(
    $organizationId: Int!
    $priceLevelType: PriceLevelType!
    $oneLevel: Int!
    $twoLevel: Int!
    $threeLevel: Int!
    $fourLevel: Int!
    $fiveLevel: Int!
    $startDate: Date!
    $endDate: Date
  ) {
    createPriceLevel(
      organizationId: $organizationId
      input: {
        priceLevelType: $priceLevelType
        startDate: $startDate
        endDate: $endDate
        oneLevel: $oneLevel
        twoLevel: $twoLevel
        threeLevel: $threeLevel
        fourLevel: $fourLevel
        fiveLevel: $fiveLevel
      }
    ) {
      priceLevel {
        priceLevelId
      }
    }
  }
`;

export const UPDATE_PRICE_LEVEL = gql`
  mutation UpdatePriceLevel(
    $priceLevelId: Int!
    $priceLevelType: PriceLevelType!
    $oneLevel: Int!
    $twoLevel: Int!
    $threeLevel: Int!
    $fourLevel: Int!
    $fiveLevel: Int!
    $startDate: Date!
    $endDate: Date
  ) {
    updatePriceLevel(
      priceLevelId: $priceLevelId
      input: {
        priceLevelType: $priceLevelType
        startDate: $startDate
        endDate: $endDate
        oneLevel: $oneLevel
        twoLevel: $twoLevel
        threeLevel: $threeLevel
        fourLevel: $fourLevel
        fiveLevel: $fiveLevel
      }
    ) {
      priceLevel {
        priceLevelId
      }
    }
  }
`;

export const DELETE_REGION = gql`
  mutation DeleteRegion($regionId: Int!) {
    deleteRegion(regionId: $regionId) {
      status
    }
  }
`;

export const UPSERT_REGION = gql`
  mutation UpsertRegion($name: String, $regionId: Int) {
    upsertRegion(input: { name: $name, regionId: $regionId }) {
      status
    }
  }
`;

export const DELETE_TERRITORY = gql`
  mutation DeleteTerritory($territoryId: Int!) {
    deleteTerritory(territoryId: $territoryId) {
      status
    }
  }
`;

export const UPSERT_TERRITORY = gql`
  mutation UpsertTerritory($name: String, $regionId: Int, $territoryId: Int) {
    upsertTerritory(input: { name: $name, regionId: $regionId, territoryId: $territoryId }) {
      status
    }
  }
`;

export const CREATE_TAG = gql`
  ${fragment.TAG_FIELDS_FRAGMENT}

  mutation CreateTag($label: String!, $tagCategoryId: Int) {
    createTag(label: $label, tagCategoryId: $tagCategoryId) {
      tag {
        ...tagFields
      }
    }
  }
`;

export const UNCOMPLETE_CASE = gql`
  mutation uncompleteCase($caseId: Int!, $reason: String!) {
    uncompleteCase(caseId: $caseId, input: { reason: $reason }) {
      status
    }
  }
`;

export const HOLD_CASE = gql`
  mutation HoldCase($caseId: Int!, $holdType: CaseHoldType!, $reason: String!) {
    holdCase(caseId: $caseId, input: { holdType: $holdType, reason: $reason }) {
      status
    }
  }
`;

export const REMOVE_HOLD = gql`
  mutation removeHold(
    $caseId: Int!
    $surgeryDate: Date!
    $isSurgeryDateTentative: Boolean
    $comment: String
  ) {
    removeHold(
      caseId: $caseId
      input: {
        surgeryDate: $surgeryDate
        isSurgeryDateTentative: $isSurgeryDateTentative
        comment: $comment
      }
    ) {
      status
    }
  }
`;

export const IMPORT_CASE = gql`
  mutation ImportCase(
    $receivedAt: Date!
    $firstName: String
    $lastName: String
    $exportData: JSON!
    $assignedUserId: Int
    $fieldRepId: Int
    $surgeonId: Int
    $locationId: Int
    $caseType: CaseType
  ) {
    importCase(
      input: {
        receivedAt: $receivedAt
        firstName: $firstName
        lastName: $lastName
        exportData: $exportData
        assignedUserId: $assignedUserId
        fieldRepId: $fieldRepId
        surgeonId: $surgeonId
        locationId: $locationId
        caseType: $caseType
      }
    ) {
      caseId
      caseNumber
      assets {
        signedUrl
        exportedAssetId
        assetType
        fileName
      }
    }
  }
`;

export const COMPLETE_CASE_IMPORT = gql`
  mutation CompleteCaseImport($caseId: Int!) {
    completeCaseImport(caseId: $caseId) {
      caseId
      caseNumber
    }
  }
`;

export const SYNC_EXTERNAL_DATA = gql`
  mutation updateSync {
    updateSync {
      count
    }
  }
`;

export const UPSERT_CASE_REPORT = gql`
  mutation UpsertCaseReport(
    $caseId: Int!
    $status: CaseReportStatusType!
    $riskAssessment: CaseRiskAssessmentType
    $correctionGoalSagittal: SagittalGoalType
    $correctionGoalSagittalOther: String
    $correctionGoalCoronal: CoronalGoalType
    $correctionGoalCoronalOther: String
    $correctionGoalDiscHeightOnly: Boolean
    $correctionGoalNote: String
    $segmentationAssetId: Int
    $segmentationSliceThickness: SegmentationSliceThicknessType
    $segmentationPassed: Boolean
    $segmentationImagingAssessment: SegmentationImagingAssessmentType
    $segmentationImageDate: Date
    $segmentedByQualifiedOperator: Boolean
    $segmentationPerformedBy: Int
    $segmentationReviewedBy: Int
    $segmentationType: SegmentationType
    $reportReviewNote: String
    $reportReviewedBy: Int
    $reportReviewedAt: DateTime
    $measurementLordosisDifference: Float
    $measurementLordosisDifferenceRationale: [LordosisDifferenceRationaleType]
    $measurementLordosisDifferenceRationaleNote: String
    $measurementNote: String
    $planFeedback: [PlanFeedbackType]
    $planFeedbackOther: String
    $casePathologies: [UpsertCasePathologyInput]
    $caseReportStatements: [UpsertCaseReportStatementInput]
  ) {
    upsertCaseReport(
      caseId: $caseId
      input: {
        caseId: $caseId
        status: $status
        riskAssessment: $riskAssessment
        correctionGoalSagittal: $correctionGoalSagittal
        correctionGoalSagittalOther: $correctionGoalSagittalOther
        correctionGoalCoronal: $correctionGoalCoronal
        correctionGoalCoronalOther: $correctionGoalCoronalOther
        correctionGoalDiscHeightOnly: $correctionGoalDiscHeightOnly
        correctionGoalNote: $correctionGoalNote
        segmentationAssetId: $segmentationAssetId
        segmentationSliceThickness: $segmentationSliceThickness
        segmentationPassed: $segmentationPassed
        segmentationImagingAssessment: $segmentationImagingAssessment
        segmentationImageDate: $segmentationImageDate
        segmentedByQualifiedOperator: $segmentedByQualifiedOperator
        segmentationPerformedBy: $segmentationPerformedBy
        segmentationReviewedBy: $segmentationReviewedBy
        segmentationType: $segmentationType
        reportReviewNote: $reportReviewNote
        reportReviewedBy: $reportReviewedBy
        reportReviewedAt: $reportReviewedAt
        measurementLordosisDifference: $measurementLordosisDifference
        measurementLordosisDifferenceRationale: $measurementLordosisDifferenceRationale
        measurementLordosisDifferenceRationaleNote: $measurementLordosisDifferenceRationaleNote
        measurementNote: $measurementNote
        planFeedback: $planFeedback
        planFeedbackOther: $planFeedbackOther
        casePathologies: $casePathologies
        caseReportStatements: $caseReportStatements
      }
    ) {
      caseReport {
        caseReportId
      }
    }
  }
`;

export const CREATE_CASE_REPORT_REVIEW_REQUEST = gql`
  mutation CreateCaseReportReviewRequest(
    $caseId: Int!
    $userId: Int!
    $reviewRequestType: ReviewRequestType!
  ) {
    createCaseReportReviewRequest(
      caseId: $caseId
      userId: $userId
      reviewRequestType: $reviewRequestType
    ) {
      status
    }
  }
`;

export const REJECT_CASE_REPORT_REVIEW = gql`
  mutation RejectCaseReportReview(
    $caseId: Int!
    $reviewResultType: ReviewResultType!
    $reportReviewNote: String
  ) {
    rejectCaseReportReview(
      caseId: $caseId
      input: { reviewResultType: $reviewResultType, reportReviewNote: $reportReviewNote }
    ) {
      status
    }
  }
`;

export const APPROVE_CASE_REPORT_REVIEW = gql`
  mutation ApproveCaseReportReview(
    $caseId: Int!
    $reviewResultType: ReviewResultType!
    $reportReviewNote: String
  ) {
    approveCaseReportReview(
      caseId: $caseId
      input: { reviewResultType: $reviewResultType, reportReviewNote: $reportReviewNote }
    ) {
      status
    }
  }
`;
