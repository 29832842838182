import { useQuery } from '@apollo/client';
import { FIND_REPORT_CASE_UPCOMING_STUDY_DATE } from '../../gql';
import { format, IReportCaseUpcomingStudyDate } from '@workflow-nx/common';
import {
  Chip,
  Link,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableHeadCell } from '../../components/TableHeadCell';
import { TableCell } from '../../components/TableCell';
import { DashboardCardView } from './DashboardCardView';
import { DateTime } from 'luxon';
import * as dateFns from 'date-fns';
import { addDays, differenceInBusinessDays, formatDistanceStrict } from 'date-fns';
import React from 'react';

function formatBusinessDistanceToNowStrict(date: Date) {
  const now = new Date();
  const businessDays = differenceInBusinessDays(now, date);

  // Create a new date that is "businessDays" days from now
  const targetDate = addDays(now, businessDays);

  // Format the distance in days using formatDistanceStrict
  return formatDistanceStrict(now, targetDate, { unit: 'day', roundingMethod: 'ceil' });
}

function getSurgeryDateColor(daysUntilSurgery: string) {
  const parsedDate = parseInt(daysUntilSurgery);

  if (parsedDate <= 20 || daysUntilSurgery === 'Today') {
    return '#c43333';
  } else if (parsedDate > 20 && parsedDate <= 25) {
    return '#ffa300';
  } else {
    return '#c9c9c9';
  }
}

const CasesByUpcomingSurgeries = () => {
  const { data } = useQuery(FIND_REPORT_CASE_UPCOMING_STUDY_DATE);

  return (
    <DashboardCardView label={'Hot Cases'}>
      <TableContainer style={{ overflow: 'auto', height: '100%' }}>
        <Table stickyHeader size={'small'}>
          <TableHead>
            <TableRow>
              <TableHeadCell>Case Number</TableHeadCell>
              <TableHeadCell align={'center'}>Stage</TableHeadCell>
              <TableHeadCell align={'center'}>Surgery Date</TableHeadCell>
              <TableHeadCell align={'center'}>Biz. Days Until Surgery</TableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.reportCaseUpcomingStudyDate?.map((report: IReportCaseUpcomingStudyDate) => {
              const surgeryDate = DateTime.fromISO(report.surgeryDate as unknown as string);
              let businessDaysUntilSurgery = formatBusinessDistanceToNowStrict(
                surgeryDate.toJSDate(),
              );

              if (Number(businessDaysUntilSurgery.replace(' days', '')) > 25) {
                return null;
              }

              if (dateFns.differenceInCalendarDays(new Date(), surgeryDate.toJSDate()) === 0) {
                businessDaysUntilSurgery = 'Today';
              }

              return (
                <TableRow key={report.caseId}>
                  <TableCell>
                    <Link
                      underline={'none'}
                      variant={'body1'}
                      href={`/app/cases/${report.caseId}`}
                      onClick={(e) => {
                        if (e.button === 2) {
                          e.preventDefault();
                        }
                      }}
                    >
                      <span style={{ cursor: 'pointer' }}>{report.number}</span>
                    </Link>
                  </TableCell>
                  <TableCell align={'center'}>{format.formatCaseStage(report.stage)}</TableCell>
                  <TableCell align={'center'}>
                    <Typography noWrap={true}>
                      {report.surgeryDate ? (
                        format.formatISODate(report.surgeryDate as unknown as string)
                      ) : (
                        <>&mdash;</>
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'}>
                    <Chip
                      size={'small'}
                      style={{
                        color: 'white',
                        backgroundColor: getSurgeryDateColor(businessDaysUntilSurgery),
                      }}
                      label={businessDaysUntilSurgery}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DashboardCardView>
  );
};

export default CasesByUpcomingSurgeries;
